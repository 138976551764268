import React from "react";
import DoctorTimePickerView from "../DoctorTimePicker/DoctrorTimePickerView";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

function AvilableTime({
  index,
  days,
  availability,
  updateAttendance,
  deleteAttendance,
  type,
  t,
}) {
  const validateEndTime = () => {
    if (
      availability.start_time &&
      availability.end_time &&
      availability.start_time >= availability.end_time
    ) {
      return "End time must be later than start time.";
    }
    return null;
  };

  const error = validateEndTime();

  return (
    <div
      className="avilable-part row mb-3 align-items-center"
      style={{ marginBottom: "40px" }}
    >
      {/* Day Selector */}
      <div className="col-lg-3 col-sm-5 mb-2">
        <FormControl fullWidth>
          <InputLabel id={`day-label-${index}`}>{t("Day")}</InputLabel>
          <Select
            labelId={`day-label-${index}`}
            name="day_of_week"
            value={availability.day}
            required
            label={"Day"}
            onChange={(e) => updateAttendance(index, "day", e.target.value)}
          >
            {days?.map((day) => (
              <MenuItem key={day.id} value={day.id}>
                {day.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* Start Time Picker */}
      <div className="col-lg-3 col-sm-5 mb-3">
        <DoctorTimePickerView
          type={type}
          title={t("Start Time")}
          value={availability.start_time}
          onChange={(value) => updateAttendance(index, "start_time", value)}
        />
      </div>

      {/* End Time Picker */}
      <div className="col-lg-3 col-sm-5 mb-3">
        <DoctorTimePickerView
          type={type}
          title={t("End Time")}
          value={availability.end_time}
          onChange={(value) => updateAttendance(index, "end_time", value)}
        />
        {error && (
          <small style={{ color: "red", display: "block", marginTop: "5px" }}>
            {error}
          </small>
        )}
      </div>

      {/* Delete Icon */}
      <div className="col-lg-1 col-sm-2 d-flex justify-content-center align-items-center">
        <DeleteIcon
          className="icon-delete"
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => deleteAttendance(index)}
        />
      </div>
    </div>
  );
}

export default AvilableTime;
