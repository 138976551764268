// ModalComponent.js
import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const ModalComponent = ({ width, open, onClose, title, children, padding }) => {
  console.log("title", title);
  const [t, i18n] = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
    >
      <Box
        sx={{
          direction: i18n.language === "ar" ? "rtl" : "ltr",
          padding: padding ? padding : "16px",
          bgcolor: "background.paper",
          maxWidth: width ? width : 400,
          width: "80vw",
          // margin: "auto",
          // marginTop: "5%  !important",
          // mt: 30,
          borderRadius: "10px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div className="wrapper-modal">{children}</div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
