import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import HeaderComponent from "../../../components/HeaderComponents/HeaderComponent";
import { fetchAppointmentById } from "../../../store/appointmentsSlice";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import Person2Icon from "@mui/icons-material/Person2";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import "./HandleAppointMentstyle.scss";
import Datatable from "../../../components/datatable/Datatable";
import CollapseTable from "../../../components/CollapseTable/CollapseTable";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import AddDetails from "./AddDetails";
import AddPayment from "./AddPayment";
import AddTransaction from "./AddTransaction";
function ManageAppointment() {
  let dispatch = useDispatch();
  let { name: type, id } = useParams();
  const [t, i18n] = useTranslation();
  let { data: dataAuth } = useSelector((state) => state.persistData.auth);
  let { loadingBTN, singleData, loadingSingle, errorSingle } = useSelector(
    (state) => state.persistData.appointments
  );

  useEffect(() => {
    dispatch(fetchAppointmentById({ id: id }));
  }, []);

  const [open, setOpen] = useState({
    status: false,
    type: "",
    data: "",
    name: "",
  });

  const DetailsColumn = [
    { field: "id", headerName: t("ID"), width: 80, sortable: "desc" },
    {
      field: "selected_datetime",
      headerName: t("Date"),
      width: 230,
      renderCell: (params) => (
        <div className="remaining">
          {params.row.selected_datetime.split(" ")[0]}
        </div>
      ),
    },
    {
      field: "extra_information",
      headerName: t("Extra Information"),
      width: 300,
    },
    { field: "note", headerName: t("Note"), width: 300 },
    {
      field: "status",
      headerName: t("Status"),
      width: 190,
      renderCell: (params) => (
        <div className={`status ${params.row.status}`}>{params.row.status}</div>
      ),
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <div className="action-icon">
          <div className="list-icons">
            <div className="icon-edit">
              <EditIcon
                onClick={() =>
                  setOpen({ status: true, type: "edit", data: params.row })
                }
                className="icon-edit"
              />
            </div>

            <div className="icon-delete">
              <ModalDelete filter="AppointmentsDetails" params={params} />
            </div>
          </div>
        </div>
      ),
    },
  ];

  console.log("singleDatasingleData", singleData);

  const hanldeClickToAddTransaction = (e) => {
    setOpen({
      status: true,
      type: "add",
      data: {
        paymentID: singleData?.appointment_payments[0]?.id,
        IdAppo: singleData.id,
      },
      name: "Transaction",
    });
  };

  const getTranslation = (translations, currentLanguage) => {
    if (!translations || !Array.isArray(translations)) return null;

    // Find the translation for the current language
    const currentLangTranslation = translations.find(
      (translation) => translation.locale === currentLanguage
    );

    // If current language is not found, fallback to any available translation
    const fallbackTranslation = translations.find(
      (translation) => translation.locale !== currentLanguage
    );

    return currentLangTranslation?.name || fallbackTranslation?.name || null;
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1 single-appo">
      <Navbar />
      <HeaderComponent title={t("Manage Appointment")}></HeaderComponent>
      <div className="two-box">
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <>
            <div className="top-detaisl row">
              <div className="box-item col-sm-3">
                <div className="box-wrap-item">
                  <div className="box-icon">
                    <Person2Icon />
                  </div>
                  <div className="dit">
                    <span>{t("Doctor Name")}:</span>
                    <p>
                      {getTranslation(
                        singleData?.doctor?.translations,
                        i18n.language
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="box-item col-sm-3">
                <div className="box-wrap-item">
                  <div className="box-icon">
                    <Person2Icon />
                  </div>
                  <div className="dit">
                    <span>{t("Patient Name")}:</span>
                    <p>
                      {getTranslation(
                        singleData?.patient?.translations,
                        i18n.language
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="box-item col-sm-3">
                <div className="box-wrap-item">
                  <div className="box-icon">
                    <AttachMoneyIcon />
                  </div>
                  <div className="dit">
                    <span>{t("Total Amount")}:</span>
                    <p>250</p>
                  </div>
                </div>
              </div>
              <div className="box-item col-sm-3">
                <div className="box-wrap-item">
                  <div className="box-icon">
                    <AttachMoneyIcon />
                  </div>
                  <div className="dit">
                    <span>{t("Total Paid")}:</span>
                    <p>100</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row box-big-details ">
              <div className="wraper-title mt-5">
                <h5 className="">{t("Appointment Details")}</h5>
                <div className="btn-list">
                  <button
                    className="btn"
                    onClick={() =>
                      setOpen({
                        status: true,
                        type: "add",
                        data: singleData.id,
                        name: "Deatils",
                      })
                    }
                  >
                    <AddIcon /> {t("Add new appointment details")}
                  </button>
                </div>
              </div>
              <Datatable
                height={"auto"}
                userColumns={DetailsColumn}
                userRows={singleData?.appointment_details || []}
              />
            </div>
            <div className="row box-big-details ">
              <div className="wraper-title mt-5">
                <h5 className="mt-5">{t("Appointment Payments")}</h5>
                <div className="btn-list">
                  <button
                    className="btn"
                    onClick={() =>
                      setOpen({
                        status: true,
                        type: "add",
                        data: singleData?.appointment_details[0]?.id,
                        name: "payment",
                      })
                    }
                  >
                    <AddIcon /> {t("Add a new payment")}
                  </button>
                </div>
              </div>
              <CollapseTable
                CLickAdd={hanldeClickToAddTransaction}
                t={t}
                appointmentPayments={singleData.appointment_payments}
              />
            </div>
          </>
        )}
      </div>

      <ModalComponent
        width={open.type === "delete" ? 500 : 400}
        open={open.status}
        onClose={() =>
          setOpen({ status: false, type: "", data: null, name: null })
        }
      >
        {open.name === "Deatils" && (
          <AddDetails
            type={open.type}
            onClose={() =>
              setOpen({ status: false, type: "", data: null, name: null })
            }
            data={open.data}
          />
        )}
        {open.name === "payment" && (
          <AddPayment
            type={open.type}
            onClose={() =>
              setOpen({ status: false, type: "", data: null, name: null })
            }
            data={open.data}
          />
        )}
        {open.name === "Transaction" && (
          <AddTransaction
            type={open.type}
            onClose={() =>
              setOpen({ status: false, type: "", data: null, name: null })
            }
            data={open.data}
          />
        )}
      </ModalComponent>
    </div>
  );
}

export default ManageAppointment;
