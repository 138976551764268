import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AvilableTime from "../AvilableTime/AvilableTime";

function AvailabilityManager({
  WeekDays,
  type,
  doctorAttendances,
  t,
  setDoctorAttendances,
}) {
  // Add new entry to doctorAttendances
  const handleShowMore = () => {
    if (doctorAttendances.length < 7) {
      setDoctorAttendances([
        ...doctorAttendances,
        { day: "", start_time: "", end_time: "" },
      ]);
    }
  };

  // Update a specific row in doctorAttendances
  const updateAttendance = (index, key, value) => {
    const updatedAttendances = [...doctorAttendances];
    updatedAttendances[index][key] = value;
    setDoctorAttendances(updatedAttendances);
  };

  // Delete a specific row from doctorAttendances
  const deleteAttendance = (index) => {
    const updatedAttendances = doctorAttendances.filter((_, i) => i !== index);
    setDoctorAttendances(updatedAttendances);
  };

  // Get list of selected days to filter options
  const selectedDays = doctorAttendances.map((attendance) => attendance.day);

  // Check if the last row is valid for enabling "Add New Component"
  const canAddNew = doctorAttendances.every(
    (attendance) =>
      attendance.day && attendance.start_time && attendance.end_time
  );

  console.log("koasdkoadskoasd", doctorAttendances);
  return (
    <div>
      {doctorAttendances.map((attendance, index) => (
        <AvilableTime
          t={t}
          key={index}
          index={index}
          days={WeekDays.filter(
            (day) => !selectedDays.includes(day.id) || day.id === attendance.day
          )} // Filter days for dropdown
          availability={attendance}
          updateAttendance={updateAttendance}
          deleteAttendance={deleteAttendance}
          type={type}
        />
      ))}
      <div className="box-icon mt-2">
        <AddIcon
          className={`icon-add ${!canAddNew ? "disabled" : ""}`} // Add disabled styling
          onClick={canAddNew ? handleShowMore : null} // Only allow adding if valid
          style={{ cursor: canAddNew ? "pointer" : "not-allowed" }}
        />
      </div>
    </div>
  );
}

export default AvailabilityManager;
