import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./Collaps.scss";

function Row({ row, CLickAdd, t }) {
  const [open, setOpen] = React.useState(false);
  const remainingAmount = parseFloat(row.total) - parseFloat(row.paid_amount);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.selected_datetime.split(" ")[0]}</TableCell>
        <TableCell align="right" className="paid-amount">
          <span>{row.paid_amount}</span>
        </TableCell>
        <TableCell align="right" className="rem-amount">
          <span> {remainingAmount.toFixed(2)}</span>
        </TableCell>
        <TableCell align="right" className="total-amount">
          <span> {row.total}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div className="wraper-title mt-5">
                <h5 className="mt-5">{t("All Transactions")}</h5>
                <div className="btn-list">
                  <button className="btn" onClick={CLickAdd}>
                    <AddIcon /> {t("Add a new Transaction")}
                  </button>
                </div>
              </div>
              <Table aria-label="transactions">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Date")}</TableCell>
                    <TableCell>{t("Cost")}</TableCell>
                    <TableCell align="right">{t("Transaction ID")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.appointment_transations?.map((transaction) => (
                    <TableRow key={transaction.id}>
                      <TableCell>
                        {transaction.selected_datetime.split(" ")[0]}
                      </TableCell>
                      <TableCell>{transaction.cost}</TableCell>
                      <TableCell align="right">
                        {transaction.transaction_id}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    selected_datetime: PropTypes.string.isRequired,
    paid_amount: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    appointment_transations: PropTypes.arrayOf(
      PropTypes.shape({
        selected_datetime: PropTypes.string.isRequired,
        cost: PropTypes.string.isRequired,
        transaction_id: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default function CollapseTable({ appointmentPayments, CLickAdd, t }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t("Payment Date")}</TableCell>
            <TableCell align="right">{t("Paid Amount")}</TableCell>
            <TableCell align="right">{t("Remaining Amount")}</TableCell>

            <TableCell align="right">{t("Total")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appointmentPayments &&
            appointmentPayments?.map((payment) => (
              <Row key={payment.id} row={payment} t={t} CLickAdd={CLickAdd} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapseTable.propTypes = {
  appointmentPayments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      selected_datetime: PropTypes.string.isRequired,
      paid_amount: PropTypes.string.isRequired,
      total: PropTypes.string.isRequired,
      appointment_transations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          selected_datetime: PropTypes.string.isRequired,
          cost: PropTypes.string.isRequired,
          transaction_id: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};
