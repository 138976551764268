import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDoctors } from "../../../store/doctorsSlice";
import { getAllpatient } from "../../../store/patientslice";
import Navbar from "../../../components/Navbar/Navbar";
import HeaderComponent from "../../../components/HeaderComponents/HeaderComponent";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { notifyError } from "../../../Notification";
import { createAppointment } from "../../../store/appointmentsSlice";

function EditCreateAppo() {
  let dispatch = useDispatch();
  let { name: type, id } = useParams();
  const [t, i18n] = useTranslation();
  let { data: dataAuth } = useSelector((state) => state.persistData.auth);
  let { loadingBTN } = useSelector((state) => state.persistData.appointments);

  let [inp, setInp] = useState({
    active: 1,
    status: "PENDING",
    company_id: dataAuth?.user?.company_id,
    appointment_details: [{ status: "PENDING" }],
    appointment_payments: [{ status: "PENDDING" }],
    "appointment_payments[0][currency_id]": 1,
  });

  const path = useNavigate();
  let handleClose = (e) => {
    path(-1);
  };

  const handleChangeDate = (e, name) => {
    setInp((prev) => {
      return {
        ...prev,
        appointment_details:
          prev?.appointment_details?.length > 0
            ? [
                {
                  ...prev.appointment_details[0],
                  selected_datetime: e.format("YYYY-MM-DD"),
                },
              ]
            : [{ selected_datetime: e.format("YYYY-MM-DD") }],

        appointment_payments:
          prev?.appointment_payments?.length > 0
            ? [
                {
                  ...prev.appointment_payments[0],
                  selected_datetime: e.format("YYYY-MM-DD"),
                },
              ]
            : [{ selected_datetime: e.format("YYYY-MM-DD") }],
      };
    });
  };

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  let handleSwitchChange = (e, name) => {
    setInp((prev) => {
      return { ...prev, [name]: e.target.checked ? 1 : 0 };
    });
  };

  useEffect(() => {
    dispatch(getAllpatient({ lang: i18n.language }));
    dispatch(fetchDoctors({ lang: i18n.language }));
  }, []);

  const {
    doctors,
    loading: loadingDoctors,
    errorAll: ErrorFetchDoctor,
  } = useSelector((state) => state.persistData.doctors);

  let {
    data: users,
    loading: loadingUsers,
    errorAll: ErrorFetchUsers,
  } = useSelector((state) => state.persistData.patients);

  const sendData = (e) => {
    e.preventDefault();
    if (type === "edit") {
      // Update appointment
    } else {
      dispatch(createAppointment({ data: inp, lang: i18n.language })).then(
        (response) => {
          if (response.payload.success) {
            path("/appointments");
          } else {
            notifyError("Failed to add Appointment. Please try again.");
          }
        }
      );
    }
  };

  console.log("inpinpinp", inp);
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <HeaderComponent
        title={type === "edit" ? t("Edit Appointment") : t("Add Appointment")}
      ></HeaderComponent>
      <div className="two-box">
        {ErrorFetchDoctor || ErrorFetchUsers ? (
          <ErrorCompo />
        ) : loadingDoctors || loadingUsers ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form className="Details row" onSubmit={(e) => sendData(e)}>
            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="Doctor">{t("Doctor")}</InputLabel>
                  <Select
                    labelId="Doctor"
                    name="doctor_id"
                    id="Doctor"
                    required
                    label={t("Doctor")}
                    onChange={(e) => getValue(e)}
                  >
                    {loadingDoctors ? (
                      // Display a loading indicator if data is still being fetched
                      <MenuItem disabled>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      // Map over the genders array to create MenuItems for each gender option
                      doctors &&
                      doctors?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="Doctor">{t("Patient")}</InputLabel>
                  <Select
                    labelId="Patient"
                    name="patient_id"
                    id="Patient"
                    required
                    label={t("Patient")}
                    onChange={(e) => getValue(e)}
                  >
                    {loadingUsers ? (
                      // Display a loading indicator if data is still being fetched
                      <MenuItem disabled>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      // Map over the genders array to create MenuItems for each gender option
                      users &&
                      users?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="status">{t("Appointment Status")}</InputLabel>
                  <Select
                    labelId="Status"
                    name="status"
                    id="status"
                    required
                    label="status"
                    value={inp.status}
                    onChange={(e) => getValue(e)}
                  >
                    <MenuItem value={"PENDING"}>{t("PENDING")}</MenuItem>
                    <MenuItem value={"DONE"}>{t("DONE")}</MenuItem>
                    <MenuItem value={"CANCELED"}>{t("CANCELED")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div
                className="form-group "
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "20px",
                  paddingLeft: "7px",
                }}
              >
                <p>
                  {t("Status")}:<sup className="redstar">*</sup>
                </p>
                <Switch
                  defaultChecked={true}
                  onChange={(event) => handleSwitchChange(event, "active")}
                />
              </div>
            </div>

            <p style={{ margin: "0px" }}>{t("Appointment Details")}:</p>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Extra information")}
                  name={"location"}
                  required
                  onChange={(e) =>
                    setInp((prev) => {
                      const extra_information = e.target.value;
                      return {
                        ...prev,
                        appointment_details:
                          prev?.appointment_details?.length > 0
                            ? [
                                {
                                  ...prev.appointment_details[0],
                                  extra_information: extra_information,
                                },
                              ]
                            : [{ extra_information: extra_information }],
                      };
                    })
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <InputLabel id="Date">{t("Date")}:</InputLabel>
                <DatePicker
                  onChange={(newValue) => {
                    handleChangeDate(newValue, "selected_datetime");
                  }}
                  defaultValue={null}
                />
              </LocalizationProvider>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Note")}
                  name={"location"}
                  //   required
                  onChange={(e) =>
                    setInp((prev) => {
                      const note = e.target.value;
                      return {
                        ...prev,
                        appointment_details:
                          prev?.appointment_details?.length > 0
                            ? [
                                {
                                  ...prev.appointment_details[0],
                                  note: note,
                                },
                              ]
                            : [{ note: note }],
                      };
                    })
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="status">{t("Details Status")}</InputLabel>
                  <Select
                    labelId="Status"
                    name="status"
                    id="status"
                    required
                    label="status"
                    value={inp?.appointment_details[0]?.status}
                    onChange={(e) =>
                      setInp((prev) => {
                        const status = e.target.value;
                        return {
                          ...prev,
                          appointment_details:
                            prev?.appointment_details?.length > 0
                              ? [
                                  {
                                    ...prev.appointment_details[0],
                                    status: status,
                                  },
                                ]
                              : [{ status: status }],
                        };
                      })
                    }
                  >
                    <MenuItem value={"PENDING"}>{t("PENDING")}</MenuItem>
                    <MenuItem value={"DONE"}>{t("DONE")}</MenuItem>
                    <MenuItem value={"CANCELED"}>{t("CANCELED")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <p style={{ margin: "0px" }}>{t("Appointment Payment")}:</p>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="payment_method">
                    {t("Payment method")}
                  </InputLabel>
                  <Select
                    labelId="Payment method"
                    name="payment_method"
                    id="payment_method"
                    required
                    label="payment_method"
                    onChange={(e) =>
                      setInp((prev) => {
                        const payment_method = e.target.value;
                        return {
                          ...prev,
                          appointment_payments:
                            prev?.appointment_payments?.length > 0
                              ? [
                                  {
                                    ...prev.appointment_payments[0],
                                    payment_method: payment_method,
                                  },
                                ]
                              : [{ payment_method: payment_method }],
                        };
                      })
                    }
                  >
                    <MenuItem value={"CASH"}>{t("CASH")}</MenuItem>
                    <MenuItem value={"CARD"}>{t("CARD")}</MenuItem>
                    <MenuItem value={"TRANSFER"}>{t("TRANSFER")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="status">{t("Payment Status")}</InputLabel>
                  <Select
                    labelId="Status"
                    name="status"
                    id="status"
                    required
                    label="status"
                    value={inp?.appointment_payments[0]?.status}
                    onChange={(e) =>
                      setInp((prev) => {
                        const status = e.target.value;
                        return {
                          ...prev,
                          appointment_payments:
                            prev?.appointment_payments?.length > 0
                              ? [
                                  {
                                    ...prev.appointment_payments[0],
                                    status: status,
                                  },
                                ]
                              : [{ status: status }],
                        };
                      })
                    }
                  >
                    <MenuItem value={"PENDDING"}>{t("PENDING")}</MenuItem>
                    <MenuItem value={"DONE"}>{t("DONE")}</MenuItem>
                    <MenuItem value={"CANCELED"}>{t("CANCELED")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"number"}
                  label={t("Total Amount")}
                  name={"total"}
                  required
                  onChange={(e) =>
                    setInp((prev) => {
                      const total = e.target.value;
                      return {
                        ...prev,
                        appointment_payments:
                          prev?.appointment_payments?.length > 0
                            ? [
                                {
                                  ...prev.appointment_payments[0],
                                  total: total,
                                },
                              ]
                            : [{ total: total }],
                      };
                    })
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            {/* <div className="col-lg-4 col-sm-6">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"number"}
                  label={t("Cost")}
                  name={"cost"}
                  required
                  onChange={(e) =>
                    setInp((prev) => {
                      const cost = e.target.value;
                      return {
                        ...prev,
                        appointment_payments:
                          prev?.appointment_payments?.length > 0
                            ? [
                                {
                                  ...prev.appointment_payments[0],
                                  cost: cost,
                                },
                              ]
                            : [{ cost: cost }],
                      };
                    })
                  }
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div> */}

            <div
              className="btn-lest mt-5"
              style={{ margin: "40px !important", justifyContent: "center" }}
            >
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba ">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Create")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Back")}
              </span>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditCreateAppo;
