import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import {
  CircularProgress,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch } from "react-redux";
import {
  addDetails,
  fetchAppointmentById,
} from "../../../store/appointmentsSlice";
import { notifyError, notifysuccess } from "../../../Notification";

function AddDetails({ type, onClose, data }) {
  const [t, i18n] = useTranslation();
  const ErrorFetchDetails = false;
  const loadingDetails = false;
  let { loadingBTN } = useSelector((state) => state.persistData.appointments);
  let dispatch = useDispatch();
  const onsubmitfn = (e) => {
    e.preventDefault();
    if (type === "edit") {
      // Update appointment
    } else {
      dispatch(addDetails({ data: inp, lang: i18n.language })).then(
        (response) => {
          if (response.payload.success) {
            dispatch(fetchAppointmentById({ id: data }));
            onClose();
          } else {
            notifyError("Failed to add Details. Please try again.");
          }
        }
      );
    }
  };

  let [inp, setInp] = useState({
    active: 1,
    status: "PENDING",
    appointment_id: data,
  });

  console.log("inpinpinp", inp);

  const handleChangeDate = (e, name) => {
    setInp((prev) => {
      return {
        ...prev,
        selected_datetime: e.format("YYYY-MM-DD"),
      };
    });
  };

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <>
      <DialogTitle>
        <Typography
          variant="h6"
          gutterBottom
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {type === "edit"
            ? t("Edit Details")
            : type === "view"
            ? t("View Details")
            : t("Add Details")}
        </Typography>
      </DialogTitle>
      <>
        {ErrorFetchDetails ? (
          <ErrorCompo />
        ) : loadingDetails ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row" onSubmit={(e) => onsubmitfn(e)}>
            <div className="col-lg-12">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Extra information")}
                  name={"extra_information"}
                  required
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <InputLabel id="Date">{t("Date")}:</InputLabel>
                <DatePicker
                  onChange={(newValue) => {
                    handleChangeDate(newValue, "selected_datetime");
                  }}
                  defaultValue={null}
                />
              </LocalizationProvider>
            </div>

            <div className="col-lg-12">
              <div className="form-group mb-4 mt-4">
                <TextField
                  id={"text"}
                  type={"text"}
                  label={t("Note")}
                  name={"note"}
                  //   required
                  onChange={(e) => getValue(e)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group mb-4 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="status">{t("Status")}</InputLabel>
                  <Select
                    labelId="Status"
                    name="status"
                    id="status"
                    required
                    label="status"
                    value={inp.status}
                    onChange={(e) => getValue(e)}
                  >
                    <MenuItem value={"PENDING"}>PENDING</MenuItem>
                    <MenuItem value={"DONE"}>DONE</MenuItem>
                    <MenuItem value={"CANCELED"}>CANCELED</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba ">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button type="submit" className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" />
                  {type === "edit" ? t("Edit") : t("Create")}
                </button>
              )}

              <span onClick={onClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </>
    </>
  );
}

export default AddDetails;
