import React, { useEffect, useState } from "react";
import Datatable from "../../../components/datatable/Datatable";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import HeaderPage from "../../../components/HeaderPage/HeaderPage";
import Navbar from "../../../components/Navbar/Navbar";
import EditIcon from "@mui/icons-material/Edit";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ModalMulti from "../../../components/ModalMulti/ModalMulti";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppointments } from "../../../store/appointmentsSlice.js";
import UpdateAppointment from "./UpdateAppointment.jsx";
import ModalComponent from "../../../components/ModalComponent/ModalComponent.jsx";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AddCardIcon from "@mui/icons-material/AddCard";
import ManageTransaction from "./ManageTransaction.jsx";
function MainAppointment() {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState({ status: false, type: "", data: "" });

  // Fetch appointments data on component mount
  useEffect(() => {
    dispatch(fetchAppointments({ lang: i18n.language }));
  }, [dispatch, i18n.language]);

  // Retrieve data from Redux store
  const { appointments, loading, errorAll } = useSelector(
    (state) => state.persistData.appointments
  );

  const userColumns = [
    { field: "uuid", headerName: t("ID"), width: 80, sortable: "desc" },
    {
      field: "patient_name",
      headerName: t("Patient Name"),
      width: 220,
      renderCell: (params) => <div>{params.row.patient.name}</div>,
    },
    {
      field: "doctor_name",
      headerName: t("Doctor Name"),
      width: 220,
      renderCell: (params) => <div>{params.row.doctor.name}</div>,
    },
    { field: "Total_Amount", headerName: t("Total Amount"), width: 180 },
    { field: "Amount_Paid", headerName: t("Total Paid"), width: 180 },
    {
      field: "status",
      headerName: t("Status"),
      width: 150,
      renderCell: (params) => (
        <div className={`status ${params.row.status}`}>{params.row.status}</div>
      ),
    },
    {
      field: "Action",
      headerName: t("Action"),
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <div className="action-icon">
          <div className="list-icons">
            <div className="icon-edit">
              <EditIcon
                onClick={() =>
                  setOpen({ status: true, type: "edit", data: params.row })
                }
                className="icon-edit"
              />
            </div>
            <div className="icon-view">
              <AddCardIcon onClick={() => gotonewfn(params.id, "Manage")} />
            </div>
            {/* <div className="icon-view">
              <CurrencyExchangeIcon
                onClick={() =>
                  setOpen({
                    status: true,
                    type: "Transaction",
                    data: params.row,
                  })
                }
              />
            </div> */}
            <div className="icon-delete">
              <ModalDelete filter="Appointments" params={params} />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const gotonewfn = (id, name) => {
    if (name === "add") navigate("/doctor/add");
    else if (name === "Manage") navigate(`/manageAppointment/${id}`);
  };

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <HeaderPage
          data={appointments}
          selectedRowIds={selectedRowIds}
          title="Appointments"
          filter="Appointments"
          link="/appointment/add"
        />
        <div className="table">
          {errorAll ? (
            <ErrorCompo />
          ) : loading ? (
            <div className="loading">
              <CircularIndeterminate />
            </div>
          ) : (
            <Datatable
              userColumns={userColumns}
              userRows={appointments || []}
              onSelectionModelChange={handleSelectionChange}
            />
          )}

          <ModalComponent
            width={open.type === "delete" ? 500 : 400}
            open={open.status}
            onClose={() => setOpen({ status: false, type: "", data: null })}
          >
            {open.type === "edit" && (
              <UpdateAppointment
                type={open.type}
                onClose={() => setOpen({ status: false, type: "", data: null })}
                data={open.data}
              />
            )}
          </ModalComponent>
        </div>
      </div>
    </div>
  );
}

export default MainAppointment;
