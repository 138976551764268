// src/slices/appointmentsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { notifyError, notifysuccess } from "../Notification";

const API_URL = "/cors/appointments";

// Async Thunks for appointment operations
export const fetchAppointments = createAsyncThunk(
  "appointments/fetchAppointments",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.get(`${BASE_URL}${API_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      console.log("response.data.result", response.data.result);
      return response.data.result.appointments.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchAppointmentById = createAsyncThunk(
  "appointments/fetchAppointmentById",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.get(`${BASE_URL}${API_URL}/${arg.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      console.log("response.data", response.data);
      return response.data.result.item;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const createAppointment = createAsyncThunk(
  "appointments/createAppointment",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.post(`${BASE_URL}${API_URL}`, arg.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const updateAppointment = createAsyncThunk(
  "appointments/updateAppointment",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.post(
        `${BASE_URL}${API_URL}/${arg.id}`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const deleteAppointment = createAsyncThunk(
  "appointments/deleteAppointment",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}${API_URL}/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const createTransaction = createAsyncThunk(
  "appointments/createTransaction",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.post(
        `${BASE_URL}${API_URL}/add-transaction`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const createPayment = createAsyncThunk(
  "appointments/createPayment",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.post(
        `${BASE_URL}${API_URL}/add-payment`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const createSession = createAsyncThunk(
  "appointments/createSession",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.post(
        `${BASE_URL}${API_URL}/add-new-session`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const addDetails = createAsyncThunk(
  "appointments/addDetails",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.post(
        `${BASE_URL}${API_URL}/add-new-session`, // Adjusted to match your endpoint
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const addPayment = createAsyncThunk(
  "appointments/addPayment",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.post(
        `${BASE_URL}${API_URL}/add-payment`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const addTransaction = createAsyncThunk(
  "appointments/addTransaction",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistData.auth.data.token;
    try {
      const response = await axios.post(
        `${BASE_URL}${API_URL}/add-transaction`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      notifysuccess(response.data.message);
      return response.data;
    } catch (error) {
      notifyError(error.response ? error.response.data.message : error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: {
    appointments: [],
    appointment: null,
    dataCreate: false,
    loading: false,
    error: null,
    dataUpdate: false,
    roles: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    errorAll: false,
    errorSingle: false,
    details: null, // Added to store details data
    paymentData: null, // Added to store paymentData data
    transactionData: null, // Added to store transactionData data
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch all appointments
      .addCase(fetchAppointments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.loading = false;
        state.appointments = action.payload;
      })
      .addCase(fetchAppointments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch appointment by ID
      .addCase(fetchAppointmentById.pending, (state) => {
        state.loadingSingle = true;
        state.error = null;
      })
      .addCase(fetchAppointmentById.fulfilled, (state, action) => {
        state.loadingSingle = false;
        state.singleData = action.payload;
      })
      .addCase(fetchAppointmentById.rejected, (state, action) => {
        state.loadingSingle = false;
        state.error = action.payload;
      })
      // Create a new appointment
      .addCase(createAppointment.pending, (state) => {
        state.loadingBTN = true;
        state.error = null;
      })
      .addCase(createAppointment.fulfilled, (state, action) => {
        state.loadingBTN = false;
        state.dataCreate = action.payload;
      })
      .addCase(createAppointment.rejected, (state, action) => {
        state.loadingBTN = false;
        state.error = action.payload;
      })

      // Add details
      .addCase(addDetails.pending, (state) => {
        state.loadingBTN = true;
        state.error = null;
      })
      .addCase(addDetails.fulfilled, (state, action) => {
        state.loadingBTN = false;
        state.details = action.payload; // Store the response as needed
      })
      .addCase(addDetails.rejected, (state, action) => {
        state.loadingBTN = false;
        state.error = action.payload;
      })

      // Add Payment
      .addCase(addPayment.pending, (state) => {
        state.loadingBTN = true;
        state.error = null;
      })
      .addCase(addPayment.fulfilled, (state, action) => {
        state.loadingBTN = false;
        state.paymentData = action.payload;
      })
      .addCase(addPayment.rejected, (state, action) => {
        state.loadingBTN = false;
        state.error = action.payload;
      })
      // Add Transaction
      .addCase(addTransaction.pending, (state) => {
        state.loadingBTN = true;
        state.error = null;
      })
      .addCase(addTransaction.fulfilled, (state, action) => {
        state.loadingBTN = false;
        state.transactionData = action.payload;
      })
      .addCase(addTransaction.rejected, (state, action) => {
        state.loadingBTN = false;
        state.error = action.payload;
      })

      // Update an appointment
      .addCase(updateAppointment.pending, (state) => {
        state.loadingBTN = true;
        state.error = null;
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        state.loadingBTN = false;
        state.dataUpdate = action.payload;
      })
      .addCase(updateAppointment.rejected, (state, action) => {
        state.loadingBTN = false;
        state.error = action.payload;
      })
      // Delete an appointment
      .addCase(deleteAppointment.pending, (state) => {
        state.loadingBTN = true;
        state.error = false;
      })
      .addCase(deleteAppointment.fulfilled, (state, action) => {
        state.appointments = state.appointments.filter(
          (e) => !action.payload.idList.includes(e.id)
        );
        state.loadingBTN = false;
        state.error = false;
        notifysuccess(action.payload.responses[0].data.message);
      })
      .addCase(deleteAppointment.rejected, (state, action) => {
        state.loadingBTN = false;
        state.error = action.payload;
      })

      // Create a transaction
      .addCase(createTransaction.pending, (state) => {
        state.loadingBTN = true;
        state.error = null;
      })
      .addCase(createTransaction.fulfilled, (state, action) => {
        state.loadingBTN = false;
        state.transaction = action.payload;
      })
      .addCase(createTransaction.rejected, (state, action) => {
        state.loadingBTN = false;
        state.error = action.payload;
      })
      // Create a payment
      .addCase(createPayment.pending, (state) => {
        state.loadingBTN = true;
        state.error = null;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.loadingBTN = false;
        state.payment = action.payload;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.loadingBTN = false;
        state.error = action.payload;
      })
      // Create a session
      .addCase(createSession.pending, (state) => {
        state.loadingBTN = true;
        state.error = null;
      })
      .addCase(createSession.fulfilled, (state, action) => {
        state.loadingBTN = false;
        state.session = action.payload;
      })
      .addCase(createSession.rejected, (state, action) => {
        state.loadingBTN = false;
        state.error = action.payload;
      });
  },
});

export default appointmentsSlice.reducer;
