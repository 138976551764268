import img1 from "../../assits/logo-w.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LogoutIcon from "@mui/icons-material/Logout";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import "./sidebar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Logout, islogged, showsliderfn } from "../../store/authslice.js";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";

function Sidebar() {
  const [t, i18n] = useTranslation();
  let { showslider } = useSelector((state) => state.persistData.auth);

  let [showMenu, setShowMenu] = useState({});
  let [toggle, setToggle] = useState(true);
  let toggleMenu = (n) => {
    setToggle(!toggle);
    setShowMenu({ name: n, state: toggle });
  };
  let dispatch = useDispatch();

  let navigate = useNavigate();
  return (
    <div className={!showslider ? "big" : "big2"}>
      <div className="list img-logo">
        <img src={img1} alt="..." />
      </div>
      <div className="list">
        <h6>{t("Main")}</h6>
        <ul>
          <NavLink to="/">
            <DashboardIcon className="icon-s" />
            {t("Home")}
          </NavLink>
        </ul>
      </div>

      <div className="list">
        <h6>{t("List")}</h6>
        <ul className="list-unstyled">
          <NavLink to="/users">
            <PeopleAltIcon className="icon-s" />
            {t("Patients Portal")}
          </NavLink>
          <NavLink to="/doctors">
            <EngineeringIcon className="icon-s" />
            {t("Workforce")}
          </NavLink>

          <NavLink to="/appointments">
            <EventNoteIcon className="icon-s" />
            {t("Appointments")}
          </NavLink>

          {/* <NavLink to="/Patients-financial">
            <AccountBalanceWalletIcon className="icon-s" />
            {t("Sessions And Finance")}
          </NavLink>

          <NavLink to="/roles-permision">
            <ManageAccountsIcon className="icon-s" />
            {t("Roles And Permission")}
          </NavLink> */}
        </ul>
      </div>

      {/* <div className="list">
        <h6>{t("Financial Management")}</h6>
        <ul className="list-unstyled">
          <a onClick={() => toggleMenu("Accounting")}>
            <CreateNewFolderIcon className="icon-s" />
            {t("Accounting")}
            {showMenu.name === "Accounting" && showMenu.state ? (
              <ArrowDropDownIcon style={{ margin: "auto" }} />
            ) : (
              <ArrowDropUpIcon style={{ margin: "auto" }} />
            )}
          </a>

          <ul
            className={`list-unstyled menu-list ${
              showMenu.name === "Accounting" && showMenu.state ? "active" : ""
            }`}
          >
            <NavLink to="/store">
              <StoreMallDirectoryIcon className="icon-s" />
              Store
            </NavLink>

            <NavLink to="/order">
              <PointOfSaleIcon className="icon-s" />
              {t("Orders")}
            </NavLink>
          </ul>
        </ul>
      </div> */}

      {/* <div className="list">
        <h6>Settings</h6>
        <ul className="list-unstyled">
          <NavLink to="/SystemHealth">
            <SettingsSuggestIcon className="icon-s" />
            Cookie Policy
          </NavLink>
          <NavLink to="/settings">
            <SettingsSuggestIcon className="icon-s" />
            Privacy Policy
          </NavLink>
        </ul>
      </div> */}

      <div className="list">
        <h6>{t("Profile")}</h6>
        <ul className="list-unstyled">
          {/* <NavLink to="/profile">
            <AccountCircleIcon className="icon-s" />
            My Profile
          </NavLink> */}
          <div
            onClick={(e) => {
              dispatch(Logout());
              navigate("/login");
            }}
          >
            <LogoutIcon className="icon-s" />
            {t("Logout")}
          </div>
        </ul>
      </div>
      {/* <div className="list">
        <h6>Theme</h6>
       *<div className="themesfather">
          <div className="theme white"></div>
          <div className="theme dark"></div>
        </div>*
        <div className="themesfather">
          <div className="theme white"></div>
          <div className="theme dark"></div>
        </div>
      </div>*/}
    </div>
  );
}

export default Sidebar;
